$red: #f53333;
$white: #fff;

.controls {
  min-width: 225px;
  height: 97px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  button {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-shadow: #bac2cb;
  }

  &__startStreamButton {
    background: #004BB4 !important;
    border: #004BB4 !important;
    width: 100%;
  }

  &__buttons {
    height: 66px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__safariFileInput {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }

  &__changeButton {
    img {
      width: 26px;
    }
  }

  &__photographButton {
    width: 66px;
    height: 66px;
    border-radius: 50px;
    border: 4px solid #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #fff;
      transform: scale(1);
      transition: transform 0.6s ease-out;

      &:active {
        transform: scale(0.7);
      }
    }
  }

  &__startRecordingButton {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background: $red !important;
  }

  &__stopRecordingButton {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: $red !important;
  }
}