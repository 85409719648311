$primary-dark: #050038;

.sameUserModal {

  &__dialog {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;

    > div {
      margin: 10px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      overflow-wrap: anywhere;
    }
  }

  &__text {
    margin: 30px 0;
    text-align: center;
    font-family: Montserrat-Medium;
    font-size: 16px;
    line-height: 22px;
    color: $primary-dark;
  }

  &__button {
    width: 100%;

    > span {
      font-family: Montserrat-SemiBold;
    }
  }
}