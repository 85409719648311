@import "colors.module";

.addProfilePicture {

  min-height: 194px;
  display: flex;
  justify-content: space-between;

  &__info {
    margin-bottom: 30px;
  }

  &__title {
    margin-bottom: 10px !important;
  }

  &__warning {
    color: $gray-darken;
    margin-bottom: 0;
  }

  &__button {
    padding: 0 !important;
    cursor: pointer;

    > span {
      display: inline-block;
      > label {
        cursor: pointer;
        padding: 13px 20px;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        font-size: 15px;
        line-height: 22px;
        font-family: Montserrat-SemiBold;
      }
    }
  }

  &__input {
    opacity: 0;
    width: 20px;
    position: absolute;
  }

  &__removeContainer {
    display: flex;
    align-items: center;
  }

  &__removeButton {
    margin-right: 10px;
    line-height: 22px;
    background: none;
    border: none;
    padding: 0;
    color: var(--primary);
  }

  &__deleteLoader {
    width: 14px !important;
    height: 14px !important;
    border-width: 2.5px !important;
  }

  @media (max-width: 768px) {
    min-height: 125px;

    &__info {
      margin-bottom: 20px;
      margin-right: 20px;
    }

    &__warning {
      line-height: 20px;
    }
  }

  :global .profile-pic {
    height: 66px;
    width: 66px;
  }
}