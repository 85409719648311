@mixin bold {
  font-family: Montserrat-Bold;
}

@mixin semiBold {
  font-family: Montserrat-SemiBold;
}

@mixin medium {
  font-family: Montserrat-Medium;
}

@mixin sub {
  font-family: Montserrat-Medium;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 18px;
}