@import "colors.module";

.cropImageModal {
  padding-top: 30px;

  &__content {
    border: 1px solid $gray-light !important;
  }

  &__title {
    font-size: 22px;
    font-family: Montserrat-Bold;
  }

  &__cropper {
    margin: 10px 0 20px 0;
    width: 100%;
    aspect-ratio: 220/128;
    
    img {
      object-fit: contain;
    }
  }
  
  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__saveBtn {
    background: none;
    color: $primary;
    border: 1px solid $primary;
    align-self: flex-end;
  }
}