.counterOfferModal {

  &__header {
    .close {
      width: 16px;
      height: 16px;
    }
  }

  &__icon {
    width: 140px;
  }

  &__top {
    text-align: center;
    margin-bottom: 20px;
  }

  &__bottom {

    label {
      font-size: 14px;
      font-family: Montserrat-Medium;
      font-weight: 400;
    }

    button {
      width: 100%;
    }
  }

  &__title {
    color: #050038;
    font-size: 22px;
    font-family: Montserrat-Bold;
    text-decoration: none;
    vertical-align: bottom;
    margin: 20px 0 8px 0;
  }

  &__subTitle {
    margin: 0;
  }

  &__inputGroup {
    margin-bottom: 20px;
  }

  &__currency--append {
    border-radius: 0 5.8px 5.8px 0 !important;
  }
}