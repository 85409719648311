@import "colors.module";

.shippingLabelModal {

  &__dialog {
    box-sizing: border-box !important;
    max-width: 1100px !important;
    width: 100%;
    padding: 0 20px;
    margin: 70px auto !important;
  }

  &__content {
    padding: 48px 90px !important;
  }

  &__title {
    font-family: MontSerrat-Bold;
    font-size: 22px;
    line-height: 27px;
    color: $primary-darken;
    justify-content: center !important;
    margin-bottom: 21px;
    text-align: center;
  }

  @media (max-width: 768px) {
    &__dialog {
      padding: 0 !important;
      margin: 0 !important;
    }

    &__content {
      border-radius: 0 !important;
      min-height: 100vh;
      padding: 54px 20px 30px 20px !important;
    }

    &__title {
      text-align: left;
    }
  }
}