.buttonWithCheckedIcon {
  padding: 5px;
  font-size: 16px;
  font-family: Montserrat-Medium;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 8px;
  }
}