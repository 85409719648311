@import "colors.module";

.roundIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary;
  padding: 3px;
  color: $white;
  border-radius: 50%;
  height: 16px;
  min-width: 16px;

  > span {
    font-size: 12px;
    line-height: 10px;
    text-align: center;
    color: $white;
  }
}