.checkoutRecapMobile {

  &__itemsContainer {

    > div {
      padding: 30px 0;

      > div:nth-child(2) {
        margin-top: 19px;
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }
}