$gray-main: #AEB3C0;
$white: #fff;

.previewItem {
  background: $gray-main;
  opacity: 0.8;
  border: none;
  color: $white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  width: 66px;
  height: 66px;
  font-size: 35px;
  padding: 0;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__videoPreview {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;

    img:first-child {
      position: absolute;
      width: 20px;
      top: 10px;
      left: 10px;
    }
  }

  &__preview {
    width: 100%;
    object-fit: fill;
  }
}