@import "breakPoints";
@import "colors.module";

.transaction-list-item {
  display: flex;
  align-items: center;
  padding: 20px 30px !important;

  &__infos-wrapper {
    // This attributes is necessary to make ellipsis behavior of
    // its children elements work
    min-width: 0;
    display: flex;
    height: 92px;
    overflow: hidden;

    > div:first-child {
      margin-right: 30px;
    }

    .react-loading-skeleton {
      margin-right: 30px;
    }

    .react-loading-skeleton.picture-skeleton {
      height: 90px;
      width: 90px;

      @media (max-width: 768px) {
        height: 118px;
        width: 118px;
      }
    }
  }

  &__image {
    width: 92px;
    height: 92px;
    min-width: 92px;
    border-radius: 6px;
    position: relative;
    // padding-top: 60%;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #e0e2e6;
    &__inner {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__button--placeholder {
    height: auto;
    padding: 14px 20px;
    border: 3px solid var(--primary);
    background-color: var(--primary);
    color: white;
    border-radius: 6px;
  }

  &__text-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
    font-family: MontSerrat-Bold;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__infos-body {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-top: 0px;
    p {
      margin: 0;

      span:first-child {
        color: $gray-darken;
      }
    }
  }

  &__price {
    &--desktop {
      color: var(--primary);
      font-size: 20px;
      font-family: MontSerrat-Bold;
      margin: auto 20px;
      margin-left: auto;
    }
    &--mobile {
      color: var(--primary);
      font-size: 20px;
      font-family: MontSerrat-Bold;
      margin-bottom: 0;
      display: none;
    }
  }

  &__infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 220px;
    min-height: 100%;
    p {
      span {
        font-size: 14px;
      }
    }
  }

  .timeline--placeholder {
    // display: flex;
    display: none;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    &__line {
      width: 100%;
      height: 8px;
      background-color: #d9dbdf;
      border-radius: 4px;
    }

    &__line-inner {
      width: 33%;
      height: 100%;
      background-color: cadetblue;
      border-radius: 4px;
    }

    &__steps {
      display: flex;
      justify-content: space-between;
    }
  }

  @include large() {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .transaction-list-item {
    padding: 0 !important;
    &__price {
      &--desktop {
        display: none;
      }
      &--mobile {
        display: block;
      }
    }

    &__infos-wrapper {
      display: flex;
      min-height: 120px;
      height: min-content;
    }

    &__infos {
      min-width: 140px;
    }

    &__image {
      min-height: 120px;
      width: 120px !important;
      min-width: 120px !important;
    }

    .timeline--placeholder {
      display: none;
    }

    &__button--placeholder,
    &__timeline--placeholder {
      display: none !important;
    }
  }
}
