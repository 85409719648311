$white: #fff;
$black: #000;
$gray-light: #D9DBDF;

.slider {
  width: 100%;
  padding: 5px 30px;
  background: $white;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -4px 10px #00000029;
  color: $black;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: MontSerrat-Bold;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 25px;
    transition: margin-bottom 0.5s ease-out;

    &WithBorder {
      border-bottom: 1px solid $gray-light;
      margin-bottom: 25px;
    }
  }

  input {
    display: none;
  }

  &__label {
    img {
      width: 18px;
      transition: transform 0.5s ease-out 0s;
    }

    &Active {
      img {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    text-align: left;
    font-family: Montserrat-Medium;
    font-size: 16px;
    line-height: 24px;
    max-height: 500px;
    transition: max-height 0.5s ease-out 0s;

    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 25px;
      }
    }

    &Invisible {
      max-height: 0;
      overflow: hidden;
    }
  }

}