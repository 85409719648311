@import "colors.module";

.container {
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 84px);
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.content {
  max-width: 720px;
  margin: auto 0;
  display: flex;
  flex-direction: column;
}

.title {
  white-space: pre-line;
  font-family: Montserrat-SemiBold;
  font-size: 24px;
  letter-spacing: -0.22px;
  line-height: 30px;
  margin-bottom: 0;
}

.form {
  margin-top: 36.5px;
  height: fit-content;
  
  hr {
    border-top: 1px solid $gray-light;
    margin: 30px 0;
  }
}

.recapLine {
  word-break: break-word;
  font-size: 18px;
  
  svg {
    margin-right: 0.5em;
  }
}

.recapMedias {
  display: grid;
  grid-gap: 5px;
  justify-content: space-evenly;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);
  }
  
  & > div {
    margin-left: auto;
    margin-right: auto;
  }
}

.back {
  padding: 0 !important;
  margin-bottom: 20px;
  align-self: flex-start;
  
  :global {
    .btn-content {
      font-family: Montserrat-Medium !important;
      font-size: 16px !important;
    }
  }
}

.nextButton {
  margin-top: 30px;
  align-self: flex-start;
}

.formLabel {
  font-size: 14px;
  white-space: break-spaces;
}

.formBottomLabel {
  font-size: 14px;
  white-space: break-spaces;
}

.textarea {
  border: 1px solid $gray-light-border;
  height: 200px !important;
  border-radius: 10px;
  resize: none;
  color: $body-color;
}

.rangeTrack {
  height: 5px;
  width: 100%;
  background-color: #d9d9d9;
  margin-top: 60px;
  margin-bottom: 30px;
}

.rangeThumb {
  height: 30px;
  width: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .label {
    position: absolute;
    margin-bottom: 90px;
    line-height: 36px;
    font-size: 36px;
  }
}

.rangeMinMaxLabels {
  display: flex;
  justify-content: space-between;
  
  p {
    font-size: 36px;
  }
}