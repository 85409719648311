@import "assets/theme.scss";
@import "colors.module";

.block-wallet {
  &__warningLink {
    color: $yellow-darken;
    font-family: Montserrat-Bold;

    &:hover {
      color: $yellow-darken;
      opacity: 0.7;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #d9dbdf;
    border-radius: 0.25rem;
    padding: 30px;
  }
  &__title {
    display: none;
    color: #050038;
    font-size: 14px;
    margin-bottom: 20px;
    font-family: Montserrat-Medium;
  }
  &__infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .balance__title-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__description {
    color: #9b99af;
    font-size: 16px;
    margin-bottom: 20px;
    &__list {
      color: #9b99af;
      p {
        margin-bottom: 0.5rem;
      }
      ul {
        list-style-type: "- ";
      }
    }
  }
  &__money {
    color: var(--primary);
    font-family: Montserrat-Bold;
    line-height: 28.8px;
    font-size: 44px;
    margin: 20px 0;
  }
  &__title-main {
    order: -1;
    color: #8a93a8;
    font-size: 20px;
    font-family: Montserrat-Bold;
    color: #050038;
    margin: 0 0 20px 0;
  }

  &__subtitle {
    font-size: 14px;
    font-family: MontSerrat-Medium;
    color: #8a93a8;
  }

  &__btn-wrapper {
    width: 100%;
  }

  &__description__btn-wrapper {
    margin-top: 30px;
    button {
      padding: 10px 16px !important;
    }

    .first-btn {
      margin-bottom: 20px !important;
    }
  }

  &__button-move {
    background: #f3f4f8 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 9px 9px;
    padding: 8px 18px;
    color: #8a93a8;
    font-size: 14px;
    border: none;
    font-family: Montserrat-SemiBold;
    height: 48px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  &__transferMessage {
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    background: $blue;
    color: $primary;
    font-family: Montserrat-Medium;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
}
.block-profile {
  border: 1px solid #d9dbdf;
  border-radius: 0.25rem;
  padding: 30px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  select.custom-select {
    color: $body-color;
    height: 48px;
    background: url("~assets/images/chevron-for-select.svg") right 0.75rem center/20px 10px no-repeat;

    option {
      color: $body-color;
    }
  }

  &__title {
    color: #050038;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: Montserrat-Bold;
  }
  &__description {
    color: #9b99af;
    font-size: 16px;
    margin-bottom: 20px;
    display: inline-block;
    &-text-underline {
      text-decoration: underline;
    }
  }

  &__delete-alert {
    background: $blue;
    color: $primary;
    font-family: Montserrat-Medium;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  &__title-main {
    color: #8a93a8;
    font-size: 20px;
    font-family: Montserrat-Bold;
    color: #050038;
    margin: 0 0 20px 0;
  }

  .form-group {
    // padding-right: 30%;
    .form-label.profile-form__label {
      color: #050038;
      font-size: 14px;
      font-family: Montserrat-Medium;
    }
    .color-input {
      color: #050038;
    }
    .form-control {
      height: 48px;
      color: #050038;
      font-size: 16px;
      &::placeholder {
        color: #9b99af;
      }
      &.invalid-success {
        border: 1px solid #00b97d;
      }
      &.placeholder,
      .placeholder {
        color: #bac2cb;
      }
    }
    .message-success {
      font-size: 12px;
      color: #00b97d;
      float: right;
      margin-top: 5px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }

  /* &__delete-profile { */
  /* border: 1px solid #ff465a; */
  /* border-radius: 8px 8px 9px 9px; */
  /* color: #FF465A; */
  /* background-color: #ffffff; */
  /* font-size: 14px; */
  /* padding: 10px; */
  /* display: block; */
  /* height: 48px; */
  /* font-family: Montserrat-SemiBold; */
  /* } */
}
@media (max-width: 768px) {
  .block-wallet {
    padding: 0;
    &__title {
      display: block;
      line-height: 18px;
    }
    &__item {
      border: none;
      padding: 0;
    }
    &__button-add-document {
      border: 1px solid #f3f4f8;
      background-color: #f3f4f8;
      color: #8a93a8;
      width: 100%;
      margin-bottom: 10px;
    }
    &__button-move {
      width: 100%;
      margin-top: 20px;
    }
    &__infos {
      align-items: center;
      width: 100%;
      border: 1px solid $gray-light;
      padding-top: 40px;
      padding-bottom: 40px;
      border-radius: 5px;
    }

    &__money {
      font-size: 44px;
      line-height: 54px;
      margin: 0 0 10px 0;
    }

    &__title-main {
      order: 1;
      font-size: 14px;
      font-family: Montserrat-Medium;
      line-height: 18px;
      color: $gray-darken;
      margin: 0;
    }

    &__subtitle {
      display: none;
    }

    &__description__btn-wrapper {
      width: 100%;
    }

    &__mobile-info-bottom {
      margin-top: 20px;
      font-family: Montserrat-Medium;
      font-size: 16px;
      line-height: 22px;
      color: $gray-darken;
    }
  }
  .block-profile {
    border: none;
    border-radius: 0;
    border-top: 1px solid #d9dbdf;
    margin-bottom: 15px;
    padding: 20px 0 15px 0;

    &:nth-of-type(3),
    &:nth-of-type(4) {
      p {
        font-family: Montserrat-Medium;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      padding-top: 0;
      border: none;
    }

    &:last-child {
      padding-bottom: 0;
    }

    > button {
      width: 100%;
    }

    &__title {
      font-family: Montserrat-Bold;
      font-size: 22px;
      line-height: 28px;
    }
    .form-group {
      padding-right: 0px;
      .form-label {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }

  .block-profile {
    &__title-main {
      font-size: 14px;
      font-family: Montserrat-Medium;
      margin: 0 0 30px 0;
    }
  }
}
