@import "colors.module";

.checkoutAddressForm {
  .toggler {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding-top: 4px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
      padding-top: 8px;
    }
  }

  .label {
    color: $gray-darken;
    font-size: 16px;
    line-height: 16px;
    font-family: Montserrat-Medium;
    margin-bottom: 0;
    margin-left: 15px;
  }

  .radio {
    padding: 3px !important;
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid #c9cdd1;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
    background-clip: content-box;

    &:checked {
      padding: 3px;
      background-color: var(--primary);
    }
  }

  .manualAddressFormContainer {
    display: flex;
    flex-direction: column;
  }

  .formGroup {
    margin-bottom: 26px !important;

    @media (max-width: 768px) {
      margin-bottom: 12px !important;
      padding-top: 8px;
    }
  }

  .input {
    min-height: 48px !important;
    border: 1px solid $gray-light;
  }

  label {
    font-size: 14px;
    font-family: Montserrat-Medium;
    line-height: 17px;
    color: $primary-darken;
  }

  input {
    color: $primary-darken !important;
    padding-left: 20px;
    padding-right: 20px;

    &::placeholder {
      color: $gray-medium;
    }
  }

  .button {
    width: 100%;
  }
}