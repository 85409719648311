@import "colors.module";

.newTransactionTemplateModal {
  color: $primary-darken;

  &__dialog {
    max-width: 960px !important;
    width: 100%;
    margin: 70px auto !important;
  }

  &__content {
    padding: 56px 40px 30px 40px !important;
  }

  &__block {
    padding: 30px;
    border: 1px solid $gray-light;
    border-radius: 5px;
    margin-bottom: 30px;
  }

  &__title {
    font-family: Montserrat-Bold;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 20px;
  }

  &__subTitle {
    font-family: Montserrat-Medium;
    font-size: 16px;
    line-height: 19px;
    color: $gray-darken;
    margin-bottom: 30px;
  }

  &__checkboxGroup {
    display: flex;
    margin-bottom: 30px;

    > label {
      margin-right: 55px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__shippingCostsCheckboxes {
    display: flex;

    > label {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__label {
    font-family: Montserrat-Medium;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  &__description {
    color: $primary-darken !important;
    resize: none;
    border-color: $gray-light;
    height: 96px !important;
    padding: 14.5px 20px !important;

    &::placeholder {
      color: $gray-medium !important;
    }
  }

  &__currencySelector {
      button {
        border: 1px solid $gray-light;
        color: $gray-darken;
        font-family: Montserrat-Medium !important;
        font-size: 16px;
        line-height: 19px !important;

        &:after {
          display: none;
        }
      }
    &-invalid {
      button {
        border-color: $red-error;
      }
    }
  }

  &__shippingCostsInputGroup {
    margin-top: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }

  &__input, &__select {
    color: $primary-darken !important;
    border-color: $gray-light;
    height: 48px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;

    &.is-invalid {
      padding-right: 70px !important;
    }

    &::placeholder {
      color: $gray-medium !important;
    }
  }

  &__select {

    &:required:invalid {
      color: $gray-medium !important;
    }

    option {
      color: $primary-darken;

      &[value=""] {
        display: none;
      }
    }
  }

  &__button {
    width: 100%;
  }

  @media (max-width: 768px) {
    &__dialog {
      margin: 0 !important;
    }

    &__content {
      border-radius: 0 !important;
      padding: 54px 25px 30px 25px !important;
    }

    &__block {
      padding: 0;
      margin-bottom: 50px;
      border: none;

      &:nth-of-type(4) {
        margin-bottom: 30px;
      }
    }

    &__title {
      margin-bottom: 10px;
    }

    &__subTitle {
      line-height: 21px;
    }

    &__shippingCostsInputGroup {
      margin-top: 30px;
    }

    &__checkboxGroup {
      flex-direction: column;

      > label {
        margin-right: 0;
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__shippingCostsCheckboxes {
      flex-direction: column;

      > label {
        margin-right: 0;
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__input, &__select, &__description {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }

  }
}