.takePicturesSecond {
  &__image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    font-family: MontSerrat-Medium;
    text-align: center;
    margin-bottom: 20px;
  }
}