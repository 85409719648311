@import "colors.module";

.radioCheckOutlined {
  min-height: 48px;
  height: 48px;
  border: 1px solid $gray-light;
  padding: 14px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__check {
    padding-left: 0;
    display: flex;
    align-items: center;
    margin-right: 12px;
  }

  & input[type="radio"] {
    position: relative;
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid $gray-light;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    padding: 2px;
    background-clip: content-box;
  }

  & input[type="radio"]:checked {
    background-color: var(--primary);
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 10px 20px;

    &__check {
      line-height: 5px;
    }
  }
}