$white: #fff;
$gray-main: #AEB3C0;

.takePicturesResult {
  width: 100%;

  &__container {
    padding: 0 30px 30px 30px;
    min-height: calc(100vh - 54px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__mediaContainer {
    margin-top: 35px;
  }

  &__title {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 26px;
    font-family: MontSerrat-Bold;
  }

  &__emptyBlock {
    width: 90px;
    height: 90px;
    visibility: hidden;
  }

  &__previewsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      button:first-child {
        z-index: 15;
        position: absolute;
        top: 6px;
        right: 6px;
        width: 14px;
        height: 14px;
        background-color: $white;
        border-radius: 50%;
        padding: 0.5px;

        > span {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          line-height: 5px;
        }

        img {
          width: 70%;
        }
      }

      button {
        width: 90px;
        height: 90px;
      }
    }
  }

  &__empty {
    background: $gray-main;
    border: none;
    color: $white;
    border-radius: 8px;
    width: 90px;
    height: 90px;
    font-size: 35px;
    padding: 0;
  }

  &__saveButton {
    width: 100%;
    height: 48px;
    margin-top: 30px;
  }

}