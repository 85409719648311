.margin-header {
  margin-top: 174px;
}

.header {
  &__link {
    &--active {
      span {
        color: var(--primary) !important;
      }
    }
  }
}

.header_new-transaction__icon {
  display: inline-block;
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.header-main {
  z-index: 2;
  padding: 23px 0 23px 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  left: 0;
  border-bottom: 1px solid #e0e2e6;

  .my-transactions {
    display: flex;
    align-items: center;
    gap: 40px;

    &__widthIcon {
      gap: 0;
    }

    &__icon {
      margin-left: 10px;
    }
  }

  &__block-info {
    display: flex;
    gap: 20px;
    .my-transactions {
      display: flex;
      align-items: center;
      padding: 20px;
      a {
        &:hover {
          text-decoration: none;
        }
      }
      &__text-info {
        color: #050038;
        font-size: 16px;
      }
      &__number {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 22px;
        display: inline-block;
        background-color: var(--primary);
        border: none;
        text-align: center;
        margin-left: 10px;
        font-size: 14px;
        color: #ffffff;
      }
    }
    .my-info {
      color: #050038;
      font-size: 18px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      button {
        color: #050038;
        font-size: 16px;
      }

      .icon-down {
        margin-left: 5px;
      }
      .custom-profile-dropdown {
        &__profile-pic {
          height: 40px;
          width: 40px;
        }

        button {
          box-shadow: none;
          font-family: Montserrat-Medium;
          display: flex;
          align-items: center;

          > div:first-child {
            margin-right: 10px;
          }

          &::after {
            display: none;
          }
        }
        .dropdown-menu {
          padding: 22px 26px 10px 26px;
          border-radius: 10px;
          width: 280px;
          //transform: translate(-151px, 65px) !important;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 8px #00000029;
          border: none;
          &::before {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            top: 18px;
            right: 45px;
            margin-top: -25px;
            transform: rotate(135deg);
            border: 2px solid #f8f8f8;
            margin-right: -26px;
            background-color: #ffffff;
            -webkit-clip-path: polygon(0 100%, 100% 100%, 0 0);
            clip-path: polygon(0 100%, 100% 100%, 0 0);
            box-shadow: 0px 0px 8px #00000029;
          }
        }
        .icon-dropdown {
          width: 34px;
          margin-right: 10px;
          height: 24px;
        }
        .dropdown-item {
          border-bottom: 1px solid #d9dbdf;
          padding: 12px 0px;
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background-color: #ffffff;
          }
        }
      }
    }
    .flag-language {
      display: flex;
      align-items: center;
      border-left: 1px solid #d9dbdf;
      padding-left: 30px;
      padding-right: 10px;
    }
  }
  &__block-icon-menu {
    display: none;
  }
  &__icon-menu-on {
    width: 28px;
    height: 22px;
  }
  &__icon-menu-off {
    width: 28px;
    height: 28px;
  }
}

.settings {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__content {
    width: 100%;
  }

  &__menu {
    max-width: 30%;
    min-width: 260px;
    margin-right: 30px;
  }
}

.block-settings {
  width: 100%;
  display: flex;
  margin-top: 160px;
  margin-bottom: auto;

  .list-menu-bar {
    padding-left: 0;
    list-style-type: none;

    .icon-list {
      width: 32px;
      margin-right: 15px;
      height: 24px;
      margin-bottom: 0.25rem;
    }
    a {
      text-decoration: none;
    }
    .text-list {
      display: flex;
      color: #050038;
      border-radius: 5px;
      padding: 15px 20px;
      margin-bottom: 10px;
      height: 48px;
      align-items: center;
    }
    .active {
      .text-list {
        background-color: var(--primary);
        color: #ffffff;
        .icon-list {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .list-menu-bar {
    padding-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .header-main {
    height: 74px;
    padding: 0 20px;
    border: none;
    &__block-info {
      display: none;
    }
    &__block-icon-menu {
      display: block;
    }

    .my-transactions.header__left {
      button {
        display: none;
      }
    }
  }

  .settings {
    &__menu {
      display: none;
    }

    &__content {
      padding: 0 !important;

      .block-payment {
        &__icon-add-plus {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .block-settings {
    margin-top: 100px;

    width: -webkit-fill-available;
    .list-menu-bar {
      display: none;
    }

    .block-payment {
      padding-top: 0;
    }
  }

  .margin-header {
    margin-top: 100px;
  }
}
