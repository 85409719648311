@import "colors.module";

.label {
  border: 1px solid $gray-light-border;
  border-radius: 10px;
  padding: 13px 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  
  .radio:checked + & {
    background-color: $gray-light-bg;
    border: 2px solid $gray-darken;
    
    .checkmark {
      display: inline;
    }
  }
}

.title {
  display: block;
}

.radio {
  display: block;
  appearance: none;
  position: static;
  margin: 0;
}

.checkmark {
  display: none;
}