@use "colors.module";

.bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.popover {
  white-space: pre-line;
  font-size: 14px;
}

@mixin dot {
  border-radius: 50%;
  height: 4px;
  width: 4px;
  margin: 2px;
  background-color: colors.$gray-light-border;
}

.dot {
  @include dot;
}

.weak {
  @include dot;
  background-color: colors.$red-error;
}

.strong {
  @include dot;
  background-color: colors.$primary;
}

.excellent {
  @include dot;
  background-color: colors.$green;
}