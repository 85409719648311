.content {
  padding-top: 30px;
  padding-bottom: 30px;
}

.image {
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 120px;
  }
}

.header {
  font-size: 20px;
  font-family: MontSerrat-Bold;
  line-height: 26px;
  margin: 20px 0;
  text-align: center;
  width: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  white-space: break-spaces;
}

.button {
  min-width: 80%;
}