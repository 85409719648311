@import "breakPoints";

.border-lg-left {
  @include large() {
    border-left: 1px solid #dee2e6 !important;
  }
}

.border-lg-right {
  @include large() {
    border-right: 1px solid #dee2e6 !important;
  }
}

.border-lg-top {
  @include large() {
    border-top: 1px solid #dee2e6 !important;
  }
}

.border-lg-bottom {
  @include large() {
    border-bottom: 1px solid #dee2e6 !important;
  }
}