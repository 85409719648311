@import "colors.module";

.confirmDeleteAccountModal {

  &__content {
    padding: 30px 20px !important;
    border-radius: 5px;
  }

  &__title {
    font-family: Montserrat-Bold;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 10px;
    color: $black;
  }

  &__info {
    font-family: Montserrat-Medium;
    font-size: 16px;
    line-height: 22px;
    color: $gray-darken;
    margin-bottom: 20px;
    text-align: center;
  }

  &__buttonsContainer {
    button {
      width: 100%;

      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
}