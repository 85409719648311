@import "assets/style/colors.module";

.processNewMediaButtons {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 30px;

  &__saveBtn {
    color: $white;
  }

  &__retryBtn {
    margin-top: 10px;
  }

}

.processCurrentMediaButtons {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__deleteBtn {
    background: $white;
    border: $white;
    color: $primary;

    span {
      width: 100%;
      height: 100%;
    }
  }

  &__closeBtn {
    width: 22px;
    height: 22px;
    font-size: 16px;
    line-height: 5px;
    border-radius: 50% !important;
    padding: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    > span {
      line-height: 5px;
    }

    img {
      max-width: 100%;
    }
  }
}