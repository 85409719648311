.informModal {
  > div > div {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 80px;
    }
  }

  &__header {
    font-size: 20px;
    font-family: MontSerrat-Bold;
    line-height: 26px;
    margin: 20px 0;
    text-align: center;
    width: 100%;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

}