@import "colors.module";

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper {
  margin: auto;
  width: 460px;
  padding: 40px 40px 30px 40px;
  border: 1px solid $gray-light;
  border-radius: 5px;
  position: relative;
}

.footer {
  margin-bottom: 1rem;
}

.logo {
  width: 135px;
  margin-bottom: 23px;
}

.title {
  font-family: MontSerrat-Bold;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px;
}

.description {
  font-family: MontSerrat-Medium;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 30px;
}

.label {
  font-family: MontSerrat-Medium;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
}

.button {
  width: 100%;
  margin-top: 23px;
}

.goBackLink {
  font-family: MontSerrat-Medium;
  font-size: 14px;
  line-height: 18px;
  position: absolute;
  left: 0;
  bottom: -48px;
}

.flyIcon {
  width: 160px;
  height: 80px;
  margin-bottom: 30px;
}

.notReceived {
  margin-bottom: 0;

  span {
    font-family: Montserrat-SemiBold;
    color: $primary;

    &:hover {
      text-decoration: underline;
    }
  }
}

.pwdStrengthBar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
}

.formGroup {
  margin-bottom: 20px !important;
  width: 100%;
}

@media (max-width: 768px) {
  .container {
    border: none;
    padding: 30px 10px;
    margin-top: 0;
  }

  .goBackLink {
    display: none;
  }
}