@import "colors.module";

.transaction-recap {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 1px solid $gray-light;
    border-radius: 5px;

    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 19px;

      div {
        max-width: 48%;
        overflow-wrap: anywhere;
      }

      &:last-child {
        border-top: 1px solid $gray-light;
        padding: 30px 0 0 0;
        margin-top: 11px;

        > div {
          font-size: 16px;
          font-family: Montserrat-Bold;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .transaction-recap {
    &__wrapper {
      padding: 30px 0;
      border: none;
    }
  }
}
