@import "colors.module";

.container {
  min-height: 182px;
  background-color: $gray-light-bg;
  border: 1px solid $gray-light-border;
  border-radius: 10px;
  padding: 15px 20px;
  
  display: grid;
  grid-gap: 5px;
  justify-content: space-evenly;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);
  }
  
  & > div {
    margin-left: auto;
    margin-right: auto;
  }
}

.addButtonContainer {
  width: 100%;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:only-child {
    grid-column: 1 / -1;
  }
}

.addButton {
  padding: 0 !important;
  height: 48px;
  
  :global {
    .form-label {
      cursor: pointer;
      padding: 11px 20px;
      display: flex;
      align-items: center;
      
      svg {
        width: 20px;
        height: 20px;
      }
      
      span {
        margin-left: 0.5rem;
      }
    }
    
    .form-file {
      position: absolute !important;
      width: 0 !important;
      opacity: 0 !important;
    }
  }
}