@import "colors.module";

.footer {
  margin-top: 26px;

  > li {
    display: flex;
    align-items: center;
    max-height: min-content;
    margin-bottom: 4px;
  }

  &__link {
    a,
    button {
      padding: 0 !important;
      font-size: 14px !important;
      line-height: 18px;
      color: $gray-darken !important;
    }
    &::after {
      content: "|";
      font-size: 14px;
      line-height: 18px;
      margin-left: 6px;
      margin-right: 6px;
      color: $gray-darken;
    }
  }

  &__button {
    border: none;
    background: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.checkout {
  &__footer {
    margin-bottom: 8px;
  }

  @media (max-width: 768px) {
    &__footer {
      margin-bottom: 4px;
    }
  }
}

.webapp {
  &__footer {
    margin: 26px 0 4px 0 !important;
  }

  @media (max-width: 768px) {
    &__footer {
      margin-bottom: 4px;
    }
  }
}