.TransactionRecapCard {
  width: 33.33%;
  .image {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    height: 216px;
    margin-bottom: 30px;
    border: 1px solid #e0e2e6;
    &__inner {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  @media (max-width: 992px) {

    width: 100%;

    .image {
      display: none;
    }
  }
}


