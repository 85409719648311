.deleteTemplateModal {

  &__content {
    min-width: 520px;
    padding: 35px 40px 30px 40px !important;
  }

  &__title {
    font-family: MontSerrat-Bold;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 20px;
  }

  &__info {
    font-family: MontSerrat-Medium;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  &__button {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    &__content {
      min-width: auto;
      padding: 35px 20px 30px 20px !important;
    }
  }

}