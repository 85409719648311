.wide-modal .modal-dialog {
  width: 70%;
  max-width: 1000px;
}

.modal-content {
  padding: 40px;
  border: none;
  position: relative;

  button.close {
    position: absolute;
    top: 36px;
    right: 36px;
    padding: 0;
  }
}

.modal-body {
  padding: 0;
  margin: 0;
}

.modal-header {
  font-size: 22px;
  font-family: Montserrat-Bold;
  padding: 0;
  border-bottom: none;
  margin-bottom: 0;

  & .close {
    background-image: url("~assets/images/icon-close.svg");
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9;
    width: 16px;
    height: 16px;

    & span {
      visibility: hidden;
    }
  }
}

.modal-footer {
  gap: 20px;
  border-top: none;
  margin-top: 20px;
  padding: 0;

  button {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .modal-content {
    padding: 20px;
  }

  .modal-dialog {
    margin-left: 20px;
    margin-right: 20px;
  }
}
