@import "colors.module";

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 76px;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 999;
  
  /* &:hover .progressBar { */
    /* height: 40px; */
  /* } */
}

.logoContainer {
  height: 76px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  height: 20px;
  padding: 0 20px;
}

.title {
  line-height: 20px;
  padding: 0 20px;
  border-left: 1px solid black;
  margin: 0;
}

.progressBar {
  position: relative;
  height: 8px;
  width: 100%;
  display: flex;
  transition: height 0.5s;
  overflow: hidden;
}

.progressTitle {
  line-height: 40px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
}

.progressActive, .progressInactive {
  height: 100%;
  width: 100%;
}

.progressActive {
  background-color: $primary;
}

.progressInactive {
  background-color: lightblue;
}