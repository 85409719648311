@import "breakPoints";

.transactions {
  margin-top: 111px;
  display: flex;
  flex-direction: column;

  .tab-content {
    margin-top: 55px;
    padding-top: 50px;
    margin-bottom: auto;
  }

  &__transaction-list {
    display: flex;
    flex-direction: column;

    > div {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    + div .spinner-loader {
      height: 100px;
    }
  }

  .transactions-tab {
    z-index: 1;
    position: fixed;
    top: 111px;
    right: 0;
    left: 0;
    background-color: #e5f4ff;
    height: 55px;
    @include large() {
      padding-left: 140px;
    }

    a:hover {
      border-color: #e5f4ff;
    }
  }

  .transactions-tab .nav-link {
    display: flex;
    align-items: center;
    margin: 0 10px;
    height: 100%;
    border: none;
    border-radius: 0;
    font-family: Montserrat-Medium;
    font-size: 16px;
    &.active {
      background: none;
      color: var(--primary);
      border-bottom: 3px solid var(--primary);
    }
  }
}

@media (max-width: 768px) {
  .transactions {
    margin-top: 74px;

    .transactions-tab {
      display: none;
      top: 74px;
    }
    .tab-content {
      margin-top: 55px;
      padding-top: 20px;
    }
  }
}
