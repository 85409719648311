@import "colors.module";

.permalinkModal {

  &__dialog {
    max-width: 520px !important;
  }

  &__content {
    padding: 55px 40px 30px 40px !important;
  }

  &__title {
    font-family: MontSerrat-Bold;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }

  &__label {
    font-family: MontSerrat-Medium;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  &__input {
    min-height: 48px;
    text-overflow: ellipsis !important;
    border: 1px solid $gray-light !important;
    border-right-width: 0 !important;
    color: $primary-darken !important;
    padding-left: 20px !important;
    padding-right: 0 !important;

    &:read-only {
      background: $white !important;
    }
  }

  &__copyButton {
    background: none;
    border: 1px solid $gray-light !important;
    border-left-width: 0 !important;
    padding-left: 10px !important;

    svg {
      width: 19px;
      height: 22px;
    }

    path {
      stroke: $gray-darken !important;
    }

    &:hover {
      border: 1px solid $gray-light;
      border-left-width: 0;
      background: none;
    }

    &:focus {
      background: none !important;
      box-shadow: none !important;
    }
  }

  &__info {
    font-family: MontSerrat-Medium;
    color: $gray-darken;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  &__button {
    width: 100%;
  }

  @media (max-width: 768px) {
    &__content {
      padding: 55px 20px 30px 20px !important;
    }

    &__input {
      padding-left: 16px !important;
    }

    &__copyButton {
      padding-right: 16px !important;
      padding-left: 14px !important;
    }

  }
}