@import "colors.module";

.dashboard {
  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 160px;
    box-sizing: border-box;
    width: 100%;
  }

  &__content {
    margin-bottom: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    width: 100%;
    gap: 0 30px;
  }

  &__card-wrapper {
    display: flex;
    padding: 30px 20px;
    height: 100%;
    min-height: min-content;
    border: 1px solid $gray-light;
    border-radius: 5px;

    > div {
      padding: 0 10px;
    }
  }

  &__profile-card,
  &__balance-card,
  &__money-card {
    width: 100%;
    padding: 0;
  }

  &__image-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  &__profile-pic {
    height: 100px;
    width: 100px;
  }

  &__edit-link {
    font-size: 14px;
    line-height: 18px;
    font-family: Montserrat-Medium;
    color: $gray-darken;
  }

  &__profile-card {
    border: 1px solid $gray-light;
    border-radius: 5px;
    position: relative;
    padding: 30px;
    min-height: min-content;
    height: 100%;
    .profile-card {
      &__profile-picture {
        width: 66px;
        height: 66px;
        border-radius: 25%;
      }

      &__name {
        font-size: 22px;
        line-height: 27px;
        font-family: Montserrat-Bold;
        margin-bottom: 8px;
      }

      &__stars {
        margin-bottom: 24px;
      }

      &__star {
        margin-right: 12px;
      }


      &__metrics {
        display: flex;
        .metrics__icon {
          width: 22px;
          height: 22px;
          margin-right: 15px;
        }
        .metrics__text {
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 0;

          span {
            font-family: Montserrat-Bold;
          }
        }
      }

      &__message {
        border-radius: 5px;
        padding: 10px;
        background: $blue;
        color: $primary;
        font-size: 14px;
        line-height: 20px;
        font-family: Montserrat-Medium;
        text-align: center;
        margin-top: 20px;
      }
    }
  }

  &__balance-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid #e0e2e6;

    .balance-card {
      &__title {
        display: flex;
        align-items: center;
        color: $gray-darken;

        .title__balance-icon {
          width: 35px;
          height: 22px;
          margin-right: 10px;
          color: $gray-darken;
        }
      }
      &__amount {
        font-size: 40px;
        line-height: 40px;
        font-family: Montserrat-Bold;
        color: $gray-darken;
      }
    }
  }

  &__money-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .money-card {
      justify-content: space-between;
      &__title {
        display: flex;
        align-items: center;
        color: $gray-darken;
        
        .title {
          &__money-icon {
            width: 17px;
            height: 22px;
            margin-right: 10px;
          }
        }
      }

      &__amount {
        font-size: 40px;
        line-height: 40px;
        font-family: Montserrat-Bold;
        color: $gray-darken;
      }
    }
  }

  @media (max-width: 992px) {
    &__content {
      grid-template-columns: 1fr 1fr;
    }

    &__profile-card {
      height: min-content;
    }

    &__card-wrapper {
      flex-direction: column;
      max-height: min-content;
      padding: 0 20px;

      > div {
        padding: 30px 10px;

        &:first-child {
          border-bottom: 1px solid #e0e2e6;
        }

        > div:nth-child(2) {
          padding: 20px 0;
        }
      }
    }

    &__balance-card {
      border: none;
    }

  }

  @media (max-width: 768px) {

    &__content {
      grid-template-columns: 1fr;
    }

    &__container {
      margin-top: 104px;
    }

    &__profile-card {
      padding: 20px;
      margin-bottom: 30px;
    }

    &__card-wrapper {
      > div {
        padding: 20px 0;
        align-items: flex-start;

        > div:nth-child(2) {
          font-family: Montserrat-Bold;
          font-size: 48px;
          line-height: 58px;
          padding-top: 23px;
          padding-bottom: 28px;
        }

        button {
          width: 100%;
        }

      }
    }

  }
}


