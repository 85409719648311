@import "colors.module";

.radioCheckbox {
    position: relative;
    padding-left: 26px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-family: MontSerrat-Medium;
    line-height: 19px;
    margin-bottom: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked {
            ~ span {
                &:after {
                    display: block;
                }
            }
        }

        &:disabled ~ p {
            color: $gray-darken;
        }
    }

    > span {
        position: absolute;
        left: 3px;
        height: 16px;
        width: 16px;
        border: 1px solid $gray-light;
        border-radius: 50%;

        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 2px;
            left: 2px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $primary;
        }
    }

    p {
        line-height: 19px;
        margin-bottom: 0;
    }
}

