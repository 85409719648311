.errorMessageBlock {
  margin-top: 10px;

  &__errorItem {
    font-family: MontSerrat-Medium;
    font-size: 14px;
    line-height: 14px;
    color: #FF6575;
    text-align: left;
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}