$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@mixin small {
  @media only screen and (min-width: $sm) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: $md) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width : $lg) {
    @content;
  }
}
@mixin extra-large {
  @media only screen and (min-width : $xl) {
    @content;
  }
}

$container-max-widths: ( xl: 1190px );