@import "colors.module";

.goBackCheckoutLink {
  background: none;
  border: none;
  padding: 0;
  margin-top: 20px;
  color: $gray-darken;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;

  &__icon {
    fill: $gray-darken;
    margin-right: 9px;
    width: 13px;
    height: 12px;
  }
}