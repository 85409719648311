@import "colors.module";

.secureFundsModal {

  &__content {
    min-width: 520px;
    padding: 30px 40px !important;
  }

  &__icon {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  &__title {
    font-size: 22px;
    font-family: Montserrat-Bold;
    line-height: 27px;
    text-align: center;
    margin: 17px 0;
  }

  &__info {
    font-family: Montserrat-Medium;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__button {
    width: 100%;
  }

  @media (max-width: 768px) {
    &__content {
      min-width: auto;
      padding: 34px 20px 30px 20px !important;
    }

    &__title {
      margin-top: 36px;
    }

  }

}