@import "colors.module";

.promocode {

  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  border-top: 1px solid $gray-light;
  &__openButtonContainer {
    width: 100%;

    > div:first-child {
      > form {
        margin-top: 0 !important;
      }
    }
  }
  &__openButton {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    background: none;
    border: none;
  }
  &__icon {
    color: var(--primary);
    margin-right: 10px;
  }
  &__label {
    padding-right: 10px;
    &--open {
      font-size: 14px;
      color: $primary-darken;
    }
    &--closed {
      font-size: 16px;
      color: $primary-darken;
    }
  }
  &__cancelBtn {
    font-size: 12px;
    color: var(--primary);
    background: none;
    border: none;
    padding: 0;
    margin-left: 10px;
  }

  &__applyBtn {
    height: 48px;
    font-size: 15px;
    margin-left: 20px;
    &:disabled {
      background: $gray-medium;
    }
  }

  &__feedback {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    color: $red-error;
  }
}

#formContainer {
  display: flex;
  margin-top: 8px;
}

#inputGroup {
  width: 100%;
  margin-bottom: 0;
  & input {
    border-radius: 5px;
    border: 1px solid $gray-light;
    height: 100%;
    width: 100%;
    max-height: 48px;
    padding: 14px 20px;
    color: $primary-darken;
    font-family: MontSerrat-Medium;

    &::placeholder {
      color: $gray-medium;
      border-color: #004bb4;
    }

    &:focus {
      box-shadow: none;
      border-color: $gray-light;
    }
  }
}

.label {
  border-radius: 5px;
  border: 1px solid $gray-light;
  height: 100%;
  max-width: 70%;
  max-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  color: $primary-darken;
  div {
    font-size: 16px;
    line-height: 16px;
    vertical-align: middle;
    overflow-wrap: anywhere;
  }
  button {
    margin-left: 20px;
    background: none;
    border: none;
    padding: 0;
  }
}

.closeIcon {
  width: 14px;
  height: 14px;
  fill: $gray-darken;
  margin-bottom: 2px;
}

.promocodeAdded {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discount {
  color: var(--primary);
}

.mobile {
  > form {
    flex-direction: column;

    > button {
      min-width: 100% !important;
      margin-left: 0;
      margin-top: 20px;
    }
  }
}