$blue: #E5F4FF;
$primary: #004BB4;

.gotoTakePictures {
  background: $blue;
  border-radius: 5px;
  padding: 12.5px 30px;
  margin: 14px 0 30px 0;
  color: $primary;
  display: flex;

  &__icon {
    min-width: 32px;
    min-height: 32px;
    margin-right: 10px;
  }

  &__text {
    font-size: 16px;
    line-height: 22px;
    font-family: Montserrat-SemiBold;

    > button {
      border: none;
      background: none;
      text-decoration: underline;
      color: $primary;
    }
  }

  @media (max-width: 768px) {

    padding: 12.5px 10px;
    margin: 4px 0 30px 0;

    &__text {
      text-align: center !important;
    }

    &__icon {
      display: none;
    }
  }
}