.takePicturesForItems {
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__content {
    padding: 54px 0 230px 0;
    background: #e7e7e7;
    min-height: 100vh;
  }

  &__disclaimer {
    background: #AEB3C0;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    font-size: 10px;
    line-height: 14px;
  }

  &__imagePreview {
    position: absolute;
    max-width: 100vw;
  }

  &__upperContainer {
    z-index: 20;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      margin: 20px auto;
    }
  }

  &__footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__streamVideo {
    width: 100vw;
    z-index: 7;
    object-fit: cover;
  }

  &__replayVideo {
    position: absolute;
    width: 100vw;
    z-index: 8;
    object-fit: cover;
  }

  &__previewContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 8;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__previewButtonsContainer {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__controls {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #00b97d;
    width: 100%;
    z-index: 9;
    display: none;
  }
}