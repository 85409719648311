.confirmModal {

  &__header {
    font-size: 20px;
    font-family: MontSerrat-Bold;
    line-height: 26px;
    margin: 10px 0;
    text-align: center;
    width: 100%;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  button {
    margin: 10px 0;
  }
}