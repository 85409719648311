.checkoutSuccess {
  width: 100%;
  height: 100%;
  text-align: center;

  &__imageContainer {
    margin-top: 48px;

    img {
      margin: 0 auto;
      width: 160px;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 26px;
    margin: 16px 0 !important;
    padding-bottom: 4px;
  }

  &__gotoTripartieButton {
    margin-top: 10px;
  }

  @media (max-width: 992px) {
    min-width: 300px;
    position: absolute;
    z-index: 1500;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    &__container {
      margin: auto;
      width: 100%;
      max-width: 600px;
    }

    &__title {
      margin: 20px 0 10px 0 !important;
      font-size: 20px !important;
      line-height: 26px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 20px;
    }

    button {
      width: 100%;
      min-height: 48px;
      font-size: 15px;
      line-height: 18px;
    }

    &__returnButton {
      margin: 0 auto;
    }

    &__gotoTripartieButton {
      margin: 10px auto;
    }
  }

  @media (max-width: 450px) {
    &__imageContainer {
      margin-top: 0;
    }
  }
}