$dark-main: #050038;
$white: #fff;

.pageTitle {
  &__header {
    z-index: 15;
    position: relative;
    width: 100%;
    height: 54px;
    max-height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }

  &__headerArrowIcon {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: none;
    border: none;
    padding: 0;
    margin: 0 !important;

    svg {
      width: 10px;
      height: 18px;
      stroke: $dark-main;
    }
  }

  &__headerTitle {
    display: flex;
    font-family: Montserrat-SemiBold;
    font-size: 16px;
    line-height: 18px;
  }

  &__numberIcon {
    width: 17px;
    height: 17px;
    background-color: $dark-main;
    border-radius: 6px;
    font-size: 12px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: MontSerrat-Bold;
    margin-right: 10px;
  }
}