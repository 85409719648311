.text-title {
  font-family: Montserrat-Bold;
  font-size: 22px;
  letter-spacing: -0.31px;
  line-height: 26.4px;
}

.text-title--modified {
  font-family: Montserrat-Bold;
  font-size: 24px;
  letter-spacing: -0.34px;
  line-height: 28.8px;
}

.text-main-bold {
  font-family: Montserrat-Bold;
  font-size: 16px;
  letter-spacing: -0.22px;
  line-height: 22px;
}

.text-main-semi-bold {
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  letter-spacing: -0.22px;
  line-height: 22px;
}

*,
.text-main {
  font-family: Montserrat-Medium;
  font-size: 16px;
  letter-spacing: -0.22px;
  line-height: 22px;
}

.text-sub {
  font-family: Montserrat-Medium;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 18px;
}

.text-small {
  font-family: Montserrat-Medium;
  font-size: 12px;
  letter-spacing: -0.2px;
  line-height: 14.4px;
}

.text-cta {
  font-family: Montserrat-SemiBold;
  font-size: 15px;
  letter-spacing: -0.21px;
  line-height: 18px;
}

.text-underline {
  text-decoration: underline;
}
