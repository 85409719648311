$primary: #004bb4;
$body-color: #050038;
$gray-darken: #8A93A8;
$gray-light: #D9DBDF;
$gray-medium: #BAC2CB;
$gray-light-bg: #F3F4F8;
$gray-light-border: #CFD1D5;
$primary-darken: #050038;
$red-error: #FF6575;
$white: #fff;
$info: #e9f7ff;
$yellow-darken: #f0a92e;
$red-darken: rgba(255, 101, 117, 0.1);
$blue: #E5F4FF;
$black: #000;
$red-primary: #FF162D;
$rating-star-yellow: #FFC200;
$gray-light-border: #E0E2E6;
$gray-max-dark: #707070;
$green: #31C99B;

:export {
  errorColor: $red-error;
  primaryColor: $primary;
  grayDarkenColor: $gray-darken;
  whiteColor: $white;
  blueColor: $blue;
  infoColor: $info;
  ratingStarYellow: $rating-star-yellow;
  grayMedium: $gray-medium;
}