@import "breakPoints";
@import "assets/theme.scss";

.checkout-container {
  margin-top: 140px;
  color: #050038;
  min-height: calc(100vh - 140px);

  @include large() {
    min-height: calc(100vh - 75px);
    margin-top: 75px;
    font-size: 18px;
  }

  &__header {
    height: 72px;
    p {
      color: #fff;
      padding-left: 15px;
      font-size: 16px;
      border-left: 1px solid #fff;
    }
  }

  &__logo {
    width: 111px;
  }

  .checkout {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 60px;

    &__content {
      // to fix top border line disappearing
      margin-top: 1px;
      margin-bottom: auto;
    }

    &__separator {
      width: 1px;
      background-color: #d9dbdf;
    }

    &__counter-price-btn {
      margin-top: 10px;
    }

    &__link {
      font-size: 12px;
    }

    &__radio {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: inline-block;
      width: 16px;
      height: 16px;
      padding: 3px;
      margin-bottom: 2px;
      background-clip: content-box;
      border: 1px solid #bbbbbb;
      background-color: #fff;
      border-radius: 50%;

      &:checked {
        background-color: var(--primary);
      }
    }

    .delivery-method {
      margin-top: 24px;
      .checkout-svg {
        height: 24px;
      }
      &__bottom-panel {
        color: #8a93a8;
      }

      &__bottomPanelButton {
        width: min-content;
        margin-right: 0;
        margin-left: auto;
      }

      &__param {
        font-family: MontSerrat-Bold;
        font-size: 16px;
        line-height: 22px;
        color: $primary-darken;
        margin-right: 0.25em;
      }

      &__value {
        font-size: 16px;
        line-height: 22px;
        color: $primary-darken;
      }

      .alert {
        color: #8a93a8;
        background-color: #f3f4f8;
      }
    }

    .parcelDelivery-label {
      margin-left: 0.25rem;
      font-family: Montserrat-Bold;
      white-space: nowrap;
    }

    .radio-payment-method {
      font-size: 16px;

      .checkout-svg {
        height: 18px;
      }
    }
  }
}

.checkout-header__slogan {
  padding-top: 4px;
  padding-bottom: 4px;
}

.checkout-header-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: fixed;
  z-index: 3;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #f3f4f8;
  color: #050038;
  padding: 20px;
}

.checkout-recap {
  &__tooltipIcon {
    padding: 10px;
  }
}

.checkout-detail-row {
  display: flex;
  justify-content: space-between;

  > div:last-child {
    width: calc(50% - 10px);
    margin-left: 20px;
  }

  margin-top: 48px;
  margin-bottom: 30px;
}

.checkout-detail-image {
  width: 220px;
  height: 128px;

  &__inner {
    width: 100%;
    height: 100%;
    content: "";
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    border: 1px solid #d9dbdf;
  }
}

.checkout-detail__desc {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
.checkout__title-total {
  font-family: Montserrat-Bold;
  font-size: 22px;
}

.checkout__changeBtn {
  padding: 0 !important;
  color: var(--primary);
  margin-left: 10px;
  font-family: Montserrat-Medium !important;
  vertical-align: bottom;
  font-size: 14px;
  line-height: 17px;

  &:hover {
    color: var(--primary);
    text-decoration: underline;
  }
  span {
    font-family: Montserrat-Medium !important;
    vertical-align: bottom;
    font-size: 14px;
    line-height: 17px;
  }
}

.checkout-radio-button {
  label {
    height: 48px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.5rem 1rem;
  }
  
  .rounded {
    border-radius: 5px;
  }

  .rounded-down {
    border-radius: 0 0 5px 5px;
  }

  .rounded-up {
    border-radius: 5px 5px 0 0;
  }
  input[type="radio"] {
    position: relative;
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid #c9cdd1;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    padding: 2px;
    margin: 0;
    margin-bottom: 2px;
    background-clip: content-box;
  }
  input[type="radio"]:checked {
    background-color: var(--primary);
  }

  input[disabled] {
    ~ span {
      color: $gray-darken;
    }

    ~ svg path, ~ svg rect {
      stroke: $gray-darken;
    }
  }

  .bottomPanelContainer {
    padding: 20px;
  }
}

@media (max-width: 992px) {
  .checkout-radio-button {
    .bottomPanelContainer {
      padding: 12px 16px 20px 16px;
    }
  }
}

.checkout__title {
  color: #050038;
  font-size: 22px;
  font-family: Montserrat-Bold;
  text-decoration: none;
  vertical-align: bottom;
  margin: 20px 0 8px 0;
  overflow-wrap: anywhere !important;

  &:hover {
    text-decoration: none;
  }

  &--small {
    font-size: 16px;
    margin-top: 7px;
    margin-bottom: 14px;
  }

  &--large {
    font-size: 20px;
    font-weight: bold;
  }
}

.checkout__tabContainer {
  margin-top: 16px;
}

.checkout__title.checkout__title {
  &--xlarge {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 29px;
  }
}

.checkout-btn-header-accordion {

  svg {
    min-width: 24px;
  }

  background-color: transparent;
  border: unset;
  width: 100%;

  .card:first-child & {
    cursor: initial;
  }

  &.disable {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.checkout-detail__other {
  letter-spacing: 0px;
  line-height: 0.5;
  font-size: 12px;
}

.checkout__tab-mobile {
  padding-top: 9px;
  justify-content: space-between;
  border: unset;
  .nav-item {
    width: calc((100% - 30px) / 4);
    padding: 0;
    height: 4px;
    background-color: #d9dbdf;
    border-radius: 0.25rem;
    border: unset;
    overflow: hidden;

    p {
      background-color: #d9dbdf;
    }

    &.active {
      border: unset;
    }
  }

  .nav-link {
    &.disabled {
      background-color: #d9dbdf;
    }
  }
}

.checkout-mobile__shape {
  width: 85px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: #fff;
}

.checkout-box-dropdown {
  padding: 0 20px;
  transition: all 0.3s;
  max-height: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  top: 120px;
  transition-property: max-height, padding;
  &.active {
    overflow-y: auto;
    padding: 25px 20px 30px 20px;
    max-height: calc(100vh - 115px);
    box-shadow: 0 6px 1rem -0.7rem #828282;
    border-radius: 0 0 5px 5px;
  }
}

.checkout-address-modal {
  &__input {
    height: 48px;
    &--merged.checkout-address-modal__input--left {
      border-radius: 0.25em 0 0 0.25em;
      border-right: 0px solid green;
    }

    &--merged.checkout-address-modal__input--right {
      border-radius: 0 0.25em 0.25em 0;
    }
  }

  &__input.form-control {
    color: #050038;
  }

  &__address {
    margin-bottom: 20px;
  }
}

.recapmobile__title {
  font-size: 20px;
  font-weight: bold;
}

.checkout__payment-choice {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.payment-form {
  &__disclaimer {
    background-color: #e5f8f2;
    color: #00b97d;
    border-radius: 4px;
    margin: 20px 0;
    padding: 16px !important;
    p small {
      display: inline-block;
      line-height: 16.8px;
      font-size: 14px;
    }
  }

  &__disclaimerIcon {
    padding-top: 2px;
    flex-shrink: 0;
    margin-right: 10px;
  }

  &__new-card-label {
    font-size: 14px;
    display: inline-block;
    margin-bottom: 8px;
  }
}

@media (max-width: 992px) {
  .checkout {
    &__mt-25 {
      margin-top: 25px;
    }
    &__mb-25 {
      margin-bottom: 25px;
    }
    &__mt-50 {
      margin-top: 50px;
    }
    &__mb-50 {
      margin-bottom: 50px;
    }

    &__title {
      font-size: 16px;
    }
  }

  .checkout-container {
    padding: 0 5px;
    margin-top: 120px;
    .checkout {
      padding-top: 0;
    }

    &__header {
      height: 60px;
    }

    &__logo {
      width: 90px;
    }
  }

  .form-login {
    padding: 0;
    .text-ask-account {
      font-size: 16px;
      margin-top: 20px;
      a {
        font-size: 16px;
      }

      button.btn.btn-link {
        span {
          font-size: 16px;
        }
      }
    }

    .text-agree {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .text-forget-password {
      font-size: 14px;
      margin: 20px auto 20px 0px;
      width: 100%;
    }

    .text-not-have-account {
      height: auto;
      .text {
        white-space: nowrap;
      }
    }
  }

  p.checkout-header {
    &__slogan {
      font-size: 12px;
    }
  }

  .checkout-detail-image {
    width: 100%;
    height: 144px;
  }

  .counter-offer-modal {
    &__icon {
      width: 102px;
    }
  }

  .checkout-delivery-btn {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {

  .checkout-container {
    padding: 0;
  }

}
