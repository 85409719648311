@import "colors.module";

.evaluateFormModal {

  &__content {
    min-width: 520px;
    padding: 55px 40px 30px 40px !important;
  }

  &__title {
    font-size: 22px;
    font-family: Montserrat-Bold;
    line-height: 29px;
    margin-bottom: 10px;
  }

  &__subtitle {
    font-family: Montserrat-Medium;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
  }

  &__star {
    width: 32px;
    height: 32px;
    margin-right: 20px;
  }

  &__starContainer {
    margin-bottom: 30px;

    &:hover {
      path[class="c"] {
        fill: $rating-star-yellow !important;
      }
    }
  }

  &__formGroup {
    margin-bottom: 0 !important;
  }

  &__label {
    font-family: MontSerrat-Medium;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }

   &__commentTextarea {
     resize: none;
     min-height: 144px;
     padding: 14px 16px 14px 20px !important;
     border: 1px solid $gray-light;
     border-radius: 5px;
     font-size: 16px;
     color: $primary-darken !important;
     background-image: none !important;

     &::placeholder {
       color: $gray-medium !important;
     }
   }

  &__errorMessage {
    display: block !important;
    font-family: MontSerrat-Medium;
    font-size: 14px !important;
    line-height: 18px;
    margin-top: 10px !important;
  }

  &__button {
    margin-top: 30px;
    width: 100%;
  }

  @media (max-width: 768px) {
    &__content {
      min-width: auto;
      padding: 55px 20px 30px 20px !important;
    }

  }

}