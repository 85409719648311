@import "colors.module";

.transactionTemplateListItem {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $gray-light-border;
  border-radius: 5px;
  padding: 12px 30px;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__title {
    font-family: MontSerrat-Bold;
    font-size: 22px;
    line-height: 27px;
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__price {
    font-family: Montserrat-Bold;
    font-size: 22px;
    line-height: 27px;
  }

  &__line {
    margin: 0 14px 0 30px;
    width: 1px;
    height: 48px;
    background: $gray-max-dark;
  }

  &__icon {
    margin-left: 16px;
    padding: 0;
    border: none;
    background: none;
  }

  @media (max-width: 768px) {
    height: 171px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;

    &__title {
      max-width: 100%;
    }

    &__line {
      display: none;
    }

    &__price {
      display: block;
      margin-bottom: 16px;
    }

    &__info {
      display: block;
    }

    &__icon {
      margin-left: 0;
      margin-right: 16px;
    }

  }
}