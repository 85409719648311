@use "fonts.module";
@use "colors.module";

.forgotPassword {
  display: block;
  color: colors.$gray-darken;
  @include fonts.sub;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.noAccountText {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  span {
    color: colors.$gray-darken;
    @include fonts.sub;

    &:first-child, &:last-child {
      height: 1px;
      flex: 1;
      background-color: colors.$gray-darken;

      @media (max-width: 768px) {
        display: none;
      }
    }
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
}
.container {
  :global {
    .form-group {
      width: 100%;
      margin-bottom: 0;
      position: relative;
    }
    
    .invalid-feedback {
      text-align: left;
      margin-bottom: 10px;
    }
    
    input.form-control {
      color: colors.$body-color;
      font-size: 16px;
      border-radius: 5px;
      box-shadow: none;
      height: 48px;
      &::placeholder {
        color: colors.$gray-medium;
        font-size: 16px;
      }
    }

    .form-row {
      .form-group:not(:first-of-type) input {
        border-left-width: 0;
      }
      .form-group {
        padding: 0;
        margin-bottom: 0;
      }
      .form-group.col {
        padding: 0;
        margin-bottom: 0;
      }
      input:not([type=checkbox]):not([type=radio]) {
        border-radius: 0;
      }
    }

    .form-row:first-of-type {
      .form-group:first-of-type input {
        border-top-left-radius: 5px;
      }
      .form-group:last-of-type input {
        border-top-right-radius: 5px;
      }
    }

    .form-row:last-of-type {
      .form-group:first-of-type input {
        border-bottom-left-radius: 5px;
      }
      .form-group:last-of-type input {
        border-bottom-right-radius: 5px;
      }
    }

    .form-row:not(:first-of-type) input:not([type=checkbox]):not([type=radio]) {
      border-top-width: 0;
    }

    .btn:not(.btn-link) {
      width: 100%;
    }
  }
}