@import "colors.module";
@import "breakPoints";

.checkoutBreadcrumbBar {
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    object-fit: contain;
    overflow: hidden;

    @media (max-width: 992px) {
      justify-content: center;
    }

    img {
      max-width: 430px;
      max-height: 36px;

      @media (max-width: 992px) {
        max-width: 315px;
        max-height: 26px;
      }
    }
  }

  &__squareLogo {
    margin-right: 0.5rem;
    width: 36px;

    @media (max-width: 992px) {
      width: 34px;
    }
  }

  &__link {
    color: #050038;
    font-size: 16px;
  }

  &__backbtn {
    stroke: $gray-darken;
    stroke-width: 2px;
    &--large {
      width: 12px;
      height: 22px;
      margin-right: 20px;
    }
    &--small {
      width: 8px;
      height: 13px;
      margin-right: 6.5px;
    }
  }
}