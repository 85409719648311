.tracking-number-modal {
  &__input-group {
    flex-direction: column;
  }

  &__text {
    margin-bottom: 30px;
  }

  &__select,
  &__input {
    width: 100% !important;
    border-radius: 5px !important;
    height: 48px !important;
  }
}
