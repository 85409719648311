@import "colors.module";

.completeTransactionModal {
  &__content {
    padding: 55px 40px 30px 40px !important;
    min-width: 520px;
  }

  &__icon {
    display: block;
    margin: 0 auto 12px auto;
  }

  &__iconBox {
    margin-bottom: 20px;
  }

  &__title {
    text-align: center;
    font-size: 22px;
    line-height: 27px;
    font-family: Montserrat-Bold;
    margin-bottom: 17px;
  }

  &__titleCompleteTransaction {
    margin-bottom: 10px;
  }

  &__subTitle {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 36px;
  }

  &__subTitleCompleteTransaction {
    margin-bottom: 0;
  }

  &__button {
    height: 48px;
    width: 100%;
    margin-top: 30px;
  }

  &__buttonCompleteTransaction {
    margin-top: 20px;
  }

  &__error {
    display: block !important;
    margin-top: 10px !important;
    font-family: Montserrat-Medium;
    font-size: 14px;
    line-height: 18px;
  }

  &__codeContainer {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }

  &__inputStyle {
    width: 48px !important;
    height: 48px !important;
    font-family: MontSerrat-Bold;
    font-size: 22px !important;
    line-height: 27px;
    color: $primary-darken !important;
  }

  &__errorStyle {
    border: 1px solid $red-error !important;
  }

  @media (max-width: 768px) {
    &__content {
      padding: 55px 20px 30px 20px !important;
      min-width: auto;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__subTitle {
      margin-bottom: 30px;
    }

    &__inputStyle {
      width: 100% !important;
      height: 48px !important;
    }

    &__codeContainer {
      > div {
        margin-right: 7.5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__titleCompleteTransaction {
      margin-bottom: 10px;
    }

    &__subTitleCompleteTransaction {
      margin-bottom: 0;
    }

  }
}


