@import "colors.module";

.manualAddressFormCheckout {

  padding-bottom: 10px;

  &__form {
    visibility: hidden;

    &--visible {
      visibility: visible;
    }
  }

  &__input {
    min-height: 48px;
  }

  &__selectError {
    color: #dc3545;
    margin-top: 0.25rem;
    font-size: 80%;
  }

  &__select {
    color: $primary-darken !important;
    min-height: 48px !important;
    padding: 6px 60px 6px 20px !important;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>') center right 10px no-repeat transparent;

    &:invalid {
      color: $gray-medium !important;
    }

    option {
      color: $primary-darken;

      &:first-child {
        color: $gray-medium !important;
      }
    }
  }

  &__inlineInputsContainer {
    display: flex;

    & div:first-child {
      margin-right: 20px;
    }
  }

  &__formGroup {
    margin-bottom: 20px !important;
    width: 100%;
  }
}
