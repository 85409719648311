@import "colors.module";

.transactionMessage {
  margin-bottom: 30px;

  &__description {
    display: flex;
    align-items: center;
  }

  &__circle {
    margin: 0 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primary);
  }

  &__name {
    font-size: 13px;
    font-style: italic;
  }

  &__date {
    margin-left: 20px;
    font-size: 13px;
    color: $gray-medium;
  }

  &__message {
    padding: 10px 20px;
    margin-bottom: 15px;
    max-height: 120px;
    overflow-wrap: break-word;
    border-radius: 8px;
    background: $info;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 10px solid $info;
      position: absolute;
      bottom: -8px;
      left: 25px;
    }
  }
}