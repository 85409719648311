@use "colors.module";
@import "breakPoints";

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper {
  margin: 94px auto auto auto;
  border: 1px solid #d9dbdf;
  padding: 40px;
  border-radius: 4px;
  max-width: 460px;
  text-align: center;
  min-width: 400px;

  @media (max-width: 768px) {
    max-width: 100%;
    border: none;
    padding: 0;
    margin-top: 20px;
  }

  svg {
    height: 32px;
    margin-bottom: 20px;
    path {
      fill: colors.$primary;
    }
  }
}

.title {
  font-family: Montserrat-Bold;
  font-size: 12px;
  letter-spacing: -0.34px;
  line-height: 28.8px;
}

.footer {
    margin-bottom: 1rem;
}