@import "colors.module";

.checkoutRecapDesktop {

  &__deliveryInfo {
    padding-top: 24px;
    padding-bottom: 26px;
  }

  &__deliveryFees {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
  }

  &__deliveryContact {
    color: $gray-darken;
    line-height: 22px;
    margin-bottom: 0;

    > span {
      display: block;
    }

  }
}