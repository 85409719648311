$primary: #004BB4;
$white: #fff;

.takePicturesFirst {
  width: 100%;
  min-height: 100vh;

  &__headerContainer {
    height: 74px;
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 135px;
    height: 32px;
  }

  &__container {
    padding: 20px 30px 30px 30px;
    min-height: calc(100vh - 74px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    text-align: center;
    font-size: 24px;
    font-family: MontSerrat-Bold;
    line-height: 29px;
    margin-bottom: 70px;
  }

  &__listItemContainer {
    display: flex;
    align-items: self-start;
  }

  &__icon {
    min-width: 26px;
    width: 26px;
    height: 26px;
    background: $primary;
    color: $white;
    font-family: MontSerrat-Bold;
    font-size: 16px;
    line-height: 22px;
    border-radius: 8px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__subTitle {
    font-family: MontSerrat-Bold;
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 9px;
  }

  &__description {
    font-family: MontSerrat-Medium;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 30px;
  }

  &__nextButton {
    margin-top: 30px;
  }
}