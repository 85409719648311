.block-menu-responsive {
  display: none;
  justify-content: space-between;
  position: fixed;
  top: 74px;
  right: 0;
  width: 100%;
  height: calc(100vh - 74px);
  z-index: 101;
  &__opacity {
    background: #000;
    opacity: 0.4;
    width: 100%;
    height: 100%;
  }
  &__list-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    a {
      text-decoration: none;
      padding: 0;
      &:hover {
        text-decoration: none;
      }
    }
    min-width: 322px;
    min-height: 100%;
    background-color: #ffffff;
    opacity: 1;
    list-style-type: none;
    padding: 30px 20px 20px 20px;
    .text-list {
      display: flex;
      color: #000;
      font-size: 16px;
      background-color: #ffffff;
      border-radius: 5px;
      align-items: center;
      margin-bottom: 30px;

      &--last {
        margin-bottom: 0;
        color: #000;
      }
    }
    .icon-list {
      width: 34px;
      height: 24px;
      margin-right: 20px;
    }
    .margin-top-list {
      margin-top: 84px;
    }

    &-bottom {
      button {
        width: 100%;
      }
    }
  }
}
