@import "colors.module";

.shippingLabelSuccessModal {

  &__content {
    min-width: 520px;
    padding: 55px 40px 30px 40px !important;
  }

  &__title {
    font-size: 22px;
    font-family: Montserrat-Bold;
    line-height: 27px;
    margin-bottom: 20px;
  }

  &__info {
    font-family: Montserrat-Medium;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 30px;
  }

  &__button {
    width: 100%;
  }

  @media (max-width: 768px) {
    &__content {
      min-width: auto;
      padding: 55px 20px 30px 20px !important;
    }

    &__info {
      margin-bottom: 20px;
    }

  }

}