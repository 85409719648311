@import "breakPoints";
@import "colors.module";

.transaction {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__disclaimer {
    margin-top: 28px;
    font-family: MontSerrat-Bold;
  }

  &__row {
    display: flex;
  }

  &__cta {
    margin: 30px 0;
    button {
      width: auto;
    }
  }

  &__cancel {
    font-size: 16px;
  }
  margin-top: 100px;
  &__reference {
    margin-bottom: 30px;
    .text-secondary {
      color: #8a93a8;
      font-size: 16px;
    }
  }
  @include large() {
    margin-top: 174px;
  }

  &__breadcrumb {
    div {
      display: inline-block;
    }
    span {
      color: #050038;
      font-size: 14px;
    }
  }

  &__intro {
    width: 100%;
    display: flex;
    padding-right: 56px;
    margin-bottom: 0;
    gap: 36px;
    &__body {
      display: flex;
      flex-direction: column;
    }

    .transaction__title-main {
      margin-bottom: 10px;
    }
  }

  &__verification-code {
    margin-top: 20px;
  }

  // Styles an img placeholder waiting the real img src
  &__img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100px;
    height: 100px;
  }

  &__button {
    width: 315px !important;
  }

  &__mt-30 {
    margin-top: 30px;
  }

  &__button-wrap {
    min-width: 280px;
    display: flex;
    flex-direction: column;
    height: 100%;

    button {
      margin-bottom: 8px;
    }

    button:last-child {
      margin-bottom: 0 !important;
    }

    span {
      font-family: Montserrat-SemiBold !important;
      font-size: 16px !important;
    }
  }

  &__suggest {
    display: flex;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    padding: 30px;
    p {
      color: #050038;
      font-size: 16px;
    }
  }
  &__title-main {
    font-size: 22px;
    color: #050038;
    font-family: Montserrat-Bold;
  }
  &__description {
    color: #050038;

    p {
      margin-bottom: 0;
    }
  }

  &__delivery-address {
    margin-top: 20px;
  }
}

.transaction-reference {
  border: 1px solid #dee2e6;
  padding: 30px;
  &__title {
    font-size: 36px;
    color: #050038;
    font-family: Montserrat-Bold;
  }
  &__price {
    font-size: 36px;
    font-family: Montserrat-Bold;
  }
}
.transaction-reference-progress {
  border-top: 1px solid #081138;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  div {
    flex: 1;
    position: relative;
    top: -12px;
    text-align: center;
    font-family: Montserrat-Bold;
    font-size: 16px;
    
    &:first-child {
      flex: .55;
      align-items: flex-start !important;
      text-align: left;
    }

    &:last-child {
      flex: .55;
      align-items: flex-end !important;
      text-align: right;
    }
  }
  
  &__check {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #081138;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 7px;
      top: 4px;
      z-index: 4;
      width: 5px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &.checked {
      background-color: #081138;
    }
  }

  &__title {
    color: #081138;
    margin-top: 10px;
    font-family: Montserrat-Medium;
    font-size: 16px;
  }
}

.transaction-detail {
  height: 100%;
  flex-grow: 1;
  margin-right: 30px;
  border: 1px solid #d9dbdf;
  padding: 30px;

  &__image {
    width: 100%;
    height: 144px;
    border-radius: 6pt;
    overflow: hidden;
    display: none;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  &__title {
    color: #050038;
    font-size: 20px;
    font-family: Montserrat-Bold;
    margin-bottom: 30px;
  }

  &__list {

    &-container {
      padding: 0 !important;
    }

    div {
      padding: 20px 0;
      border-bottom: 1px solid #e0e2e6;

      &:last-child {
        border-bottom: 0;
      }
    }

    p {
      color: #081138;
      font-size: 16px;
      overflow-wrap: anywhere;
    }
  }
}

.transaction-sold-by {
  &__title {
    color: #050038;
    font-size: 22px;
    font-family: Montserrat-Bold;
  }
  &__name {
    margin-top: auto;
    font-size: 16px;
    color: #081138;
  }
  &__tag {
    background-color: rgba(0, 128, 0, 0.45);
    padding: 2px 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    span {
      margin-bottom: 0;
      &:first-child {
        margin-right: 3px;
        // margin-bottom: 3px;
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: rgba(0, 128, 0, 1);
        border: 1px solid rgba(0, 128, 0, 1);
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 5px;
          top: 3px;
          z-index: 4;
          width: 4px;
          height: 6px;
          border: solid white;
          border-width: 0 1px 1px 0;
          transform: rotate(45deg);
        }
      }
      &:last-child {
        font-size: 10px;
        color: rgba(0, 128, 0, 1);
      }
    }
  }
}

.transaction-item {
  &__image {
    width: 120px;
    height: 120px;
    border: 1px solid #e0e2e6;
    border-radius: 6px;
  }
  &__image-inner {
    width: 120px;
    height: 120px;
    aspect-ratio: 100%;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.transaction-image {
  img {
    width: 100%;
    object-fit: cover;
  }
  &__inner {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    // padding-top: 58%; /* aspect-ratio: 220 / 128; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
  }
}

@media (max-width: 992px) {
  .transaction {

    &__row {
      flex-direction: column;
    }

    &-detail {
      margin-right: 0;
      margin-bottom: 30px;

      &__image {
        display: flex;
      }
    }

    &__suggest {
      flex-direction: column;
      margin-top: 0;
    }

    &__intro {
      padding-right: 0;
      padding-top: 0;
    }

    &__button-wrap {
      > button:first-child {
        margin-top: 20px;
      }
    }

  }

}

@media (max-width: 768px) {
  .transaction {
    &__suggest {
      padding: 20px;
    }

    &__disclaimer {
      margin-top: 35px;
    }

    &__intro {
      flex-direction: column;
      align-items: center;
      gap: 0;
      &__body {
        text-align: center;
        align-items: center;
      }
    }

    &__verification-code,
    &__delivery-address {
      margin-top: 0;
    }

    &__img-placeholder {
      margin-bottom: 20px;
      width: 72px;
      height: 72px;
    }

    &__button {
      width: 100% !important;
    }

    &__button-wrap {
      min-width: 100% !important;
    }

    &__cta {
      margin-bottom: 20px;
      margin-top: 0;
      button {
        width: 100%;
      }
    }
  }

  .transaction-detail {
    border: none;
    margin-bottom: 0;
    padding: 0;
    border-bottom: 1px solid $gray-light !important;

    &__title {
      margin-bottom: 19px;
    }

    &__list {

      &-container {
        padding-bottom: 11px !important;
      }

      div {
        padding-top: 0;
        padding-bottom: 19px;
        border: none;
      }
    }

    &-address {
      flex-direction: column;
      padding: 30px 0 30px 0 !important;
      border-top: 1px solid $gray-light !important;

      p:first-child {
        padding-bottom: 10px;
      }

      p:last-child {
        max-width: 100% !important;
        line-height: 26px;
        color: $gray-darken;
        text-align: left !important;
      }
    }

  }

  .transaction-reference {
    border: none;
    padding: 0;
    
    &__title,
    &__price {
      font-size: 20px;
    }

    &__id {
      display: none;
    }

    &-progress div {
      p {
        font-size: 14px;
      }
      
      &:nth-child(2) p {
        font-family: Montserrat-Bold;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 500px) {

  .transaction-detail {

    &__list {

      div {
        p:last-child {
          max-width: 148px;
        }
      }
    }
  }
}
