.alert-info {
  color: #004bb4;
  background-color: #e9f7ff;
  border-color: #e9f7ff;
}

.alert-success {
  color: #00b97d;
  background-color: #e5f8f2;
  border-color: #e5f8f2;
}

.alert-warning {
  color: #f0a92e;
  background-color: #fdf6ea;
  border-color: #fdf6ea;
}

.alert-danger {
  color: #ff162d;
  background-color: #ffe7ea;
  border-color: #ffe7ea;
}