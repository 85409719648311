@import "colors.module";

.tutoModal {
  text-align: center;
  
  @media (max-width: 992px) {
    padding-left: 0 !important;
  }
}

.modalContent {
  padding: 0 !important;
  overflow: hidden;
}

.modalDialog {
  max-width: 660px !important;
  padding-left: 0;
}

.body {
  margin: 0;
  padding: 0;
  border-radius: 5px;
}

.image {
  @media (max-width: 992px) {
    height: 240px;
    display: flex;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
}

.content {
  padding: 30px;
  
  @media (max-width: 992px) {
    padding: 20px;
  }
}

.radio {
  cursor: pointer;
  height: 17px;
  width: 17px;
  margin-left: 6px;
  margin-right: 6px;
}

.title {
  font-family: Montserrat-Bold;
  font-size: 22px;
  margin: 28px 40px 16px;
  line-height: 26px;
  
  @media (max-width: 992px) {
    font-size: 20px;
    margin: 20px auto 10px;
  }
}

.text {
  font-family: Montserrat-Medium;
  margin: 0 40px 23px;
  margin-right: 40px;
  font-size: 16px;
  line-height: 22px;
  
  @media (max-width: 992px) {
    margin: 0 auto 0;
    line-height: 26px;
  }
}

.buttonsRow {
  :global {
    @media (max-width: 992px) {
      padding: 20px;
      
      .col {
        padding: 0;
      }
      
      .col:first-child {
         margin-right: 9px;
      }
    }
  
    .col {
      display: flex;
    }

    .col:first-child {
      justify-content: flex-end;
    }
    
    .col:last-child {
      justify-content: flex-start;
    }
  }
  
  .button {
    font-size: 14px;
    height: 48px;
    width: 100%;
    max-width: 160px;
    
    @media (max-width: 992px) {
       max-width: 100%;
    }
  }
}

.hideButton {
  padding-top: 20px !important;
  padding-bottom: 0px !important;
  
  @media (max-width: 992px) {
    padding-top: 0 !important;
  }
  
  :global {
    .btn-content {
      color: var(--primary);
      font-size: 13px !important;
      font-family: MontSerrat-Medium !important;
      text-decoration: underline;
    }
  }
}

.languageSelector {
  padding-top: 20px;
  display: flex;
  justify-content: center;

  span {
    color: $primary-darken !important;
  }
}