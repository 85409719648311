.passwordInput {
  position: relative;

  input {
    padding-right: 40px;
    height: 48px;
  }

  .pwdStrengthBar {
    position: absolute;
    top: 0;
    right: 2px;
    height: 48px;
  }

  .changeButton {
    display: flex;
    align-items: center;
    height: 48px;
    padding-right: 15px;
    position: absolute;
    top: 0;
    right: 0;

    button {
      border: 0;
      background: 0;
      padding: 0;

      img {
        height: 16px;
        width: 23px;
      }
    }
  }
}