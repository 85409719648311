@import "colors.module";

.cancelRejectModal {

  &__dialog {
    max-width: 520px !important;
    margin-left: auto !important;
    margin-right: auto !important;

    & > div {
      padding: 30px 40px;
    }

    @media (max-width: 560px) {
      max-width: 335px !important;
      width: 335px !important;
      padding-top: 12px;
    }
  }

  &__header {
    margin-top: 25px;
    margin-bottom: 30px;
    font-family: MontSerrat-Bold;
    font-size: 22px;
    line-height: 26px;
    color: $primary-darken;
  }

  &__button {
    width: 100%;
    margin-top: 10px;

    span {
      font-family: Montserrat-SemiBold;
    }
  }

  &__info {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {

    &__button {
      margin-top: 0;
    }

    &__dialog {
      > div {
        padding: 20px;
      }
    }

    &__header {
      font-size: 20px;
      margin-top: 36px;
      margin-bottom: 20px;
    }

  }
}