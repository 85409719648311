#previewCarousel {
  width: 100%;
  height: 66px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  > a {
    width: 10%;
    opacity: 1;

    img {
      width: 6px;
      height: 18px;
    }
  }

  .previewCarousel {

    &__slideContainer {
      display: flex;
      justify-content: center;
    }

    &__itemsContainer {
      display: flex;
      justify-content: space-between;
      max-width: 300px;
      width: 80%;
    }
  }
}
