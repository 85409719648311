@import "colors.module";

.modal-add-payment {
  &__header {
    margin-bottom: 10px !important;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__content {
      margin-top: 30px;
      font-size: 22px;
      font-family: Montserrat-Bold;
    }
  }

  .form-content {
    .placeholder {
      color: #bac2cb;
    }
    .color-input {
      color: #050038;
    }
    .form-group {
      margin-bottom: 0px;
      .form-control {
        color: #050038;
        border-radius: 0px;
        height: 48px;
        box-shadow: none;
        &::placeholder {
          color: #bac2cb;
          font-size: 16px;
        }
      }
    }
    .form-group-top {
      margin-bottom: 20px;
      .form-control {
        border-radius: 5px;
      }
    }
    .form-group-border-center-left {
      padding-right: 0;
      .form-control {
        border-right: 0;
      }
    }
    .form-group-border-center-right {
      padding-left: 0;
    }
    .form-group-border-bottom-left {
      padding-right: 0;
      .form-control {
        border-right: 0;
        border-bottom-left-radius: 5px;
      }
    }
    .form-group-border-bottom-right {
      padding-left: 0;
      .form-control {
        border-bottom-right-radius: 5px;
        background: url("~assets/images/icon-card.svg") 95% 50% no-repeat;
        background-size: 25px 25px;
        padding-right: 40px;
        background-color: #ffffff;
      }
    }
    .form-group-border-top {
      .form-control {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-bottom: 0;
      }
    }
    .form-group-border-bottom {
      .form-control {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-top: 0;
      }
    }
  }
  &__button-submit {
    margin: 30px 0 0 0;
    width: 100%;
    padding: 11px;
    font-size: 16px;
    color: #ffffff;
    height: 48px;
    font-family: Montserrat-SemiBold;
  }
  &__description {
    font-size: #050038;
    font-size: 16px;
  }
  &__text-holder-address {
    font-family: Montserrat-Medium;
    font-size: 14px;
    line-height: 18px;
    color: #050038;
  }
  .custom-file-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30%;
  }

  .custom-select {
    border-radius: 0 0 5px 5px;
    border-top: none;
    height: 48px;
  }
}

.bank-form__row {
  margin-top: 10px;
}

.block-payment {
  margin-bottom: 20px;
  border: 1px solid #d9dbdf;
  border-radius: 0.25rem;
  padding: 30px 30px 0 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &__items {
    margin-bottom: 30px;
  }

  &__subtitle {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__description {
    color: #9b99af;
    font-size: 16px;
    margin-bottom: 20px;
    display: inline-block;
  }

  &__noAccount {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
    color: $black;
  }

  &__item {
    flex-wrap: nowrap;
    padding: 25px;
    .col {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__card-info {
    padding: 0 30px;
    margin-left: -30px;
    margin-right: -30px;
  }

  &__bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #d9dbdf;
  }

  &__text-add {
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  &__icon-add-plus {
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
    margin-right: 12px;
  }

  .block-payment-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 14px 0;
    flex-wrap: nowrap;

    .rib-info {
      p {
        margin-bottom: 0;
        font-family: Montserrat-Medium;
        font-size: 16px;
        line-height: 19px;
        color: $black;
      }
    }

    &__cardInfo {
      display: flex;
      align-items: center;

      p {
        line-height: 19px;
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    &__expire {
      margin-left: 20px;
    }

    &__currency {
      margin-left: 20px;
      font-family: Montserrat-SemiBold;
      color: $gray-darken;
    }

    .card-provider-logo {
      margin-right: 10px;
    }

    .delete-btn {
      color: $red-primary;
      font-size: 10px;
      border: 1px solid $red-primary;
      border-radius: 0.25rem;
      padding: 5px 15px;
      background-color: $white;
      font-family: Montserrat-SemiBold;

      &:active,
      &:hover {
        border: 1px solid $red-primary;
        background-color: $red-primary;
        color: $white;
      }
    }
    &__icon-invalid {
      width: 20px;
      margin-left: 20px;
      height: 20px;
    }
    .card-provider-logo {
      height: 22px;
      width: 36px;
      align-self: center;
    }
  }
}
.form-add-payment-responsive {
  &__title {
    font-size: 14px;
    color: #050038;
    display: flex;
    align-items: center;
    margin-bottom: 21px;
  }
  &__icon-left {
    width: 17px;
    margin-right: 5px;
  }
  .modal-add-payment {
    &__button-submit {
      margin-top: 20px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 768px) {
  .block-payment {
    padding: 0;
    border: 0;
    margin-bottom: 30px;

    &:nth-child(2) {
        margin-bottom: 0;

        div:last-child {
          border-bottom: 0;
        }
    }

    &__subtitle {
      font-family: Montserrat-Bold;
      font-size: 22px;
      line-height: 28px;
      margin-top: 0;
      margin-bottom: 10px;
    }

    &__card-info {
      padding: 0;
      margin: 0;

      &:nth-of-type(1) {
        margin-top: 20px;
      }

      > div {
        border-top: 1px solid $gray-light;
      }

      &:last-child {
        > div {
          border-bottom: 1px solid $gray-light;
        }
      }
    }

    .block-payment-item {
      padding-bottom: 16px;
      padding-top: 16px;
      margin-bottom: 0;
      margin-top: 0;

      .delete-btn {
        height: 22px;
        padding: 0 11px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-family: Montserrat-SemiBold;
          font-size: 9.5px;
          line-height: 11px;
        }
      }

    }

    &__bottom {
      padding-top: 20px;
      padding-bottom: 20px;

      span {
        font-family: Montserrat-SemiBold;
        font-size: 15px;
        line-height: 19px;
      }
    }
    &-remove {
      border-top: 1px solid #d9dbdf;
      border-bottom: 1px solid #d9dbdf;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &__text-add {
      color: #050038;
    }
  }
}
