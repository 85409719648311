#notAvailableModal {
    max-width: 1060px;
    width: 80%;
    margin: 70px auto;

  & > div {
    max-height: 760px;
    padding: 50px;
    min-height: calc(100vh - 100px);
  }

  .notAvailableModal {
    &__title {
      margin: 66px 0 16px 0;
      width: 100%;
      text-align: center;
      font-size: 22px;
      line-height: 26px;
      font-family: MontSerrat-Bold;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__qrCode {
      margin: 30px;
    }

    &__text {
      text-align: center;
      font-size: 16px;
      line-height: 22px;
      font-family: MontSerrat-Medium;

      &--top {
        margin-bottom: 20px;
      }

      &--bottom {
        margin-bottom: 0;
      }
    }
  }
}