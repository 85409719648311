@import "colors.module";

.profilePicture {
  position: relative;
  object-fit: cover;
  display: flex;
  justify-content: center;

  &__image {
    min-width: 100%;
    object-fit: cover;
    border: 1px solid $white;
  }

  &__mask {
    fill: $white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}