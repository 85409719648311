.paymentLinksTab {
  &__title {
    margin-bottom: 10px;
    font-family: MontSerrat-Bold;
    font-size: 22px;
    line-height: 27px;
  }

  &__message {
    margin-top: 20px;
    font-family: MontSerrat-Medium;
    font-size: 16px;
  }

  &__container {
    padding-top: 20px;
  }

  @media (max-width: 768px) {

    &__container {
      padding-top: 0;
    }

    &__message {
      margin-top: 0;
    }
  }
}